import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SUB_EDITION_BASE_FRAGMENT = `
  fragment subEditionBaseFragment on SubEdition {
    uid
    schemaCode
    name
    code
    description
    autoFollow
    archived
    startTime
    startTimestamp
    endTime
    endTimestamp
    logoFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
